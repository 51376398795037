import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  baseUrl = environment.apiUrl;
  constructor(private apiservice: ApiService) {}

  createUser(formData: any): Observable<any> {
    let path = `${this.baseUrl}/auth`;
    return this.apiservice.post(path, formData);
  }

  getUserList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/users?page=${pageNumber}&limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}`;
    return this.apiservice.get(path);
  }

  editUserList(body: any) {
    const path = `${this.baseUrl}/auth`;
    return this.apiservice.put(path, body);
  }

  addRoleAndPermission(formData: any) {
    const path = `${this.baseUrl}/auth/role`;
    return this.apiservice.post(path, formData);
  }

  updateRole(body: any) {
    const path = `${this.baseUrl}/auth/role`;
    return this.apiservice.put(path, body);
  }

  updateStatus(body: any) {
    const path = `${this.baseUrl}/auth/user/status`;
    return this.apiservice.put(path, body);
  }

  getRolePermissionList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/roles?page=${pageNumber}&limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}`;
    return this.apiservice.get(path);
  }

  getUserById(id: string): Observable<any> {
    const path = `${this.baseUrl}/auth/user/${id}`;
    return this.apiservice.get(path);
  }

  getRoleById(id: string): Observable<any> {
    const path = `${this.baseUrl}/auth/role/${id}`;
    return this.apiservice.get(path);
  }

  getRoleModules(id: string): Observable<any> {
    const path = `${this.baseUrl}/auth/role/modules?userRoleID=${id}`;
    return this.apiservice.get(path);
  }

  assignPermission(formData: any) {
    const path = `${this.baseUrl}/auth/role/assign`;
    return this.apiservice.post(path, formData);
  }

  unAssignPermission(formData: any) {
    const path = `${this.baseUrl}/auth/role/un-assign`;
    return this.apiservice.post(path, formData);
  }

  changePassword(body: any) {
    const path = `${this.baseUrl}/auth`;
    return this.apiservice.put(path, body);
  }

  resetPassword(body: any) {
    const path = `${this.baseUrl}/auth/user/password`;
    return this.apiservice.put(path, body);
  }
}
